import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';

const Navbar = () => {
    return (
        <nav className=''>
            <div className='flex container mx-auto'>
                <ul className='flex grow items-center p-4'>
                    <li className='grow h-12'><Link to="/"><img alt="logo" className="object-contain h-12" src="/logo.png" /></Link></li>
                    <li className='pl-4'><Link to="/expense/add"><Button color='purple'>Add Expense</Button></Link></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
