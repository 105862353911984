import React from 'react';
import AddExpense from './AddExpense';
import Home from './Home';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/expense/add" element={<AddExpense />} />
    </Routes>
  );
}

export default App;
