import React from 'react';
import { TextInput, NumberInput, NativeSelect, FileInput, Button, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import { PutObjectCommand } from '@aws-sdk/client-s3'
import { r2 } from '../lib/r2'
import Navbar from '../components/Navbar';
import { Notification } from '@mantine/core';

function AddExpense() {
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const form = useForm({
        initialValues: {
            description: '',
            type: 'External',
            person: 'Home',
            category: 'Grocery',
            amount: '',
            date: new Date()
        },
    });

    const submitForm = async (data) => {
        setLoading(true);
        setSuccess(false);
        if (data.file) {
            const params = {
                Bucket: process.env.REACT_APP_R2_BUCKET_NAME,
                Key: "/bills/" + data.file.name,
                Body: data.file,
            };

            const command = new PutObjectCommand(params);
            const res = await r2.send(command);
            if (res.$metadata.httpStatusCode === 200) {
                const body = { "Date": data.date.toDateString(), "Type": data.type, "Month": data.date.getMonth() + 1, "Amount": data.amount, "Person": data.person, "Bill URL": "/bills/" + data.file.name, "Category": data.category, "Description": data.description };
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body)
                };
                fetch('https://post-fi.shritk.com/', options)
                    .then(response => response.json())
                    .then(() => {
                        form.reset();
                        setLoading(false);
                        setSuccess(true);
                    })
                    .catch(err => console.error(err));
            }
        } else {
            const body = { "Date": data.date.toDateString(), "Type": data.type, "Month": data.date.getMonth() + 1, "Amount": data.amount, "Person": data.person, "Category": data.category, "Description": data.description };
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            fetch('https://post-fi.shritk.com/', options)
                .then(response => response.json())
                .then(() => {
                    form.reset();
                    setLoading(false);
                    setSuccess(true);
                })
                .catch(err => console.error(err));
        }
    }

    return (
        <div className="min-h-screen">
            <Navbar />
            <div className='container mx-auto p-4'>
                <Box maw={340} mx="auto">
                    <form onSubmit={form.onSubmit((values) => submitForm(values))}>
                        <TextInput
                            withAsterisk
                            label="Description"
                            placeholder="Description of the expense"
                            {...form.getInputProps('description')}
                            className="mb-4"
                        />

                        <NativeSelect
                            label="Type"
                            data={['External', 'Internal']}
                            {...form.getInputProps('type')}
                            className="mb-4" />

                        <NativeSelect
                            label="Person"
                            data={['Bobo', 'Shri', 'Home', 'Rambo', 'Family', 'Viyan']}
                            {...form.getInputProps('person')}
                            className="mb-4" />


                        <NativeSelect
                            label="Category"
                            data={[
                                'Bills & Utilities',
                                'Debt',
                                'Food & Dining',
                                'Entertainment & Lesiure',
                                'Family',
                                'Gifts',
                                'Grocery',
                                'Health & Medical',
                                'Household',
                                'Others',
                                'Personal Care',
                                'Rent',
                                'Subscriptions',
                                'Travel',
                                'Vehicle',
                                'Openknack'
                            ]}
                            {...form.getInputProps('category')}
                            className="mb-4" />

                        <NumberInput
                            withAsterisk
                            label="Amount"
                            placeholder="Quantum of the expense"
                            {...form.getInputProps('amount')}
                            className="mb-4"
                        />

                        <DateInput
                            label="Date "
                            placeholder="Date of expense"
                            {...form.getInputProps('date')}
                            className="mb-4"
                        />

                        <FileInput
                            label="Bill"
                            placeholder="Upload bill"
                            {...form.getInputProps('file')}
                            className="mb-8"
                        />

                        <Button type="submit" fullWidth color='purple' className='w-full' loading={loading} disabled={loading || !form.isTouched()}>Add Expense</Button>
                    </form>
                    {success && <Notification withBorder color="green" title="Success" className="my-4" onClose={() => setSuccess(false)}>
                        Expense successfully added
                    </Notification>}
                </Box>
            </div>
        </div>
    );
}

export default AddExpense;