import React from 'react';
import Navbar from '../components/Navbar';

function Home() {
    return (
        <div className="min-h-screen">
            <Navbar />
            <div className='container mx-auto p-4'>
                <iframe width="100%" height="100%" className="w-full h-screen fi-analytics" src="https://lookerstudio.google.com/embed/reporting/ca69c189-e807-435e-80b2-9d324ce2f4c6/page/LLMvD" frameborder="0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox" title="expense analytics"></iframe>
            </div>
        </div>
    );
}

export default Home;
