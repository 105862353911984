import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './screens/App';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createTheme, MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import './styles/global.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  colors: {
    purple: ["#f6ebff",
      "#e6d3fe",
      "#c8a3f8",
      "#aa6ff3",
      "#9045ef",
      "#7f2aed",
      "#771ced",
      "#6610d3",
      "#5a0bbd",
      "#4e04a6"],
    blue: ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
  },
});
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
